import httpClient from "@/services/httpClient";

var mime = require("mime-types");

class MDGenerateService {
	async generatePSCL(format, company_id, office_id, survey_id, ignore_office, step) {
		if(ignore_office == "true"){
			ignore_office = 1;
		}
		return httpClient({
			method: "post",
			url: `/pscl/report/${format}/${company_id}/${office_id}/${survey_id}/${ignore_office}/${step}.json`,
		});
	}    

	async pushToNextCloud( company_id, office_id, survey_id, ignore_office, step) {
		//convert ignore_office to integer
		if(ignore_office == "true"){
			ignore_office = 1;
		}
		return httpClient({
			method: "post",
			url: `/pscl/pushTo/nextcloud/${company_id}/${office_id}/${survey_id}/${ignore_office}/${step}.json`,
		});
	}

	async getStatus( company_id, office_id, survey_id) {
		return httpClient({
			method: "get",
			url: `/pscl/get_queue_status/${company_id}/${office_id}/${survey_id}.json`,
		});
	}

	async pullFromNextCloud( company_id, office_id, survey_id, ignore_office, step) {
		//convert ignore_office to integer
		if(ignore_office == "true"){
			ignore_office = 1;
		}
		return httpClient({
			method: "post",
			url: `/pscl/pullFrom/nextcloud/${company_id}/${office_id}/${survey_id}/${ignore_office}/${step}.json`,
		});
	}
}

export default new MDGenerateService();
