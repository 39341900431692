<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light" v-if="loggedUser">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">{{ routeName }}</a>
        <button
          class="navbar-toggler navbar-burger"
          type="button"
          @click="toggleSidebar"
          :aria-expanded="$sidebar.showSidebar"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-bar"></span>
          <span class="navbar-toggler-bar"></span>
          <span class="navbar-toggler-bar"></span>
        </button>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" v-if="canShowUserGuide">
              <a class="nav-link" @click="download('users/get-faq')" href="#" :disabled="btnloading">
                <span class="ti-help-alt"></span>
                Faq
              </a>
            </li>
            <li class="nav-item" v-if="canShowUserGuide">      
              <a class="nav-link" @click="download('users/get-guide')" href="#" :disabled="btnloading">
                <span class="ti-book"></span>
                Manuale Utente
              </a>
            </li>
            <drop-down
              class="nav-item"
              :title="username"
              title-classes="nav-link"
              icon="ti-user">
              <router-link class="dropdown-item" to="/profile">Modifica Profilo</router-link>
              <a class="dropdown-item" @click="logout">Logout</a>
            </drop-down>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>

import Util from "@/mixing/util";
import UserService from "@/services/user.service";



export default {
	mixins: [Util],
	computed: {
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
		username() {
			return this.loggedUser ? this.loggedUser.email : null;
		},
	},
	data() {
		return {
			loggedUser: null,
      btnloading: false,
		};
	},
	async created() {
		this.loggedUser = this.getUser;
	},
	methods: {
    download(url) {
			this.btnloading = true;
			UserService.download(url).then(() => {
				this.btnloading = false;
			});
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
	},
};
</script>
<style></style>
