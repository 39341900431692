<template>
    <div>
        <b-tabs content-class="mt-3" @input="onTabChange()">
            <b-tab title="Tabella" active>
                <b-table small selectable striped :items="result_from_backend" :fields="fields" :busy="busy">
                    <template #cell(riduzione_km_gg_auto)="data">
                        {{ data.value | round2 }}
                    </template>
					<template #head(riduzione_km_gg_auto)>
						Riduzione Km auto/anno
                    </template>
                    <template #cell(Consumo)="data">
                        {{ data.value | round2 }}
                    </template>
					<template #head(Consumo)>
						Risparmio di carburante [l]
                    </template>
                    <template #cell(CO)="data">
                        {{ data.value | round2 }}
                    </template>
					<template #head(CO)>
                        Risparmio di CO [Kg]
                    </template>
                    <template #cell(CO2)="data">
                        {{ data.value | round2 }}
                    </template>
					<template #head(CO2)>
                        Risparmio di CO2 [Kg]
                    </template>
                    <template #cell(NOx)="data">
                        {{ data.value | round2 }}
                    </template>
					<template #head(NOx)>
                        Risparmio di NOx [Kg]
                    </template>
                    <template #cell(PM10)="data">
                        {{ data.value | round2 }}
                    </template>
					<template #head(PM10)>
                        Risparmio di PM10 [Kg]
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Istogramma" style="height: 1050px">
                <GChart type="ColumnChart" :resizeDebounce="0" :data="emissionsChartData" :options="chartOptions" />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import Util from "@/mixing/util";
//import { ChartCard } from "@/components/index";
import emissionsConst from "@/data/emissions-const";
import Filters from "@/mixing/filters";

import { GChart } from "vue-google-charts";

import UserService from "@/services/user.service";

export default {
	name: "Impact",

	mixins: [Filters, Util],
	props: {
		measureId: Number,
		officeId: Number,
		surveyId: Number,
		// rowIndex: Number,
		measure: Object,
		monitoring: Object,
		busy: Boolean,
	},
	components: {
		//ChartCard,
		GChart,
	},
	data() {
		return {
			ec: emissionsConst,
			chart: null,
			monitorings: null,
			fields: ["name", "riduzione_km_gg_auto", "Consumo", "CO", "CO2", "NOx" , "PM10"],
			emissionsChartData: [["name"], ["Riduzione Km \n auto/anno"], ["Consumo \n [litri/100km]"], ["CO \n[Kg/km]"], ["CO2 \n[Kg/km]"], ["NOx \n[Kg/km]"], ["PM10 \n[Kg/km]"]],
			chartOptions: {
				title: null,
				titleTextStyle: {
					bold: false,
				},
				height: 700,
				width: 1300,
				vAxis: {
					title: "",
				},
				legend: { position: "right" },
			},
			result_from_backend: null,
		};
	},
	methods: {
		onTabChange() {
			this.$forceUpdate();
		},
		// calculateIndicator(serie) {
		//   if (serie == undefined) {
		//     return {};
		//   }
		//   let A = this.measure.indicator(serie);
		//   if (A == null) {
		//     return {};
		//   } else {
		//     return A.getOutput();
		//   }
		// },
	},
	async mounted() {
		let chartData = [["name", "Riduzione Km auto/anno", "Consumo", "CO", "CO2", "NOx" , "PM10"]];
		let row = 0;
		this.monitorings = this.monitoring;

		if (this.monitorings != undefined || this.monitorings != null) {
			// this.monitoring.series.forEach((element) => {
			//   this.monitorings.out[row] = this.calculateIndicator(element);
			//   this.monitorings.out[row].name = element.name;
			//   row++;
			// });

			try {
				let rslt = await UserService.getPsclMeasureImpacts(this.officeId, this.surveyId);
				//console.log(rslt);
				this.result_from_backend = rslt.data.impacts[this.measureId];
				//console.log(this.result_from_backend);

				if (this.result_from_backend != undefined) {
					this.result_from_backend.forEach((element) => {
						let tmp = [];
						delete element.monitoring;
						delete element.officeName;
						for (let prop in element) {
							if (prop == "name") {
								tmp.unshift(element[prop]);
							}
							if (prop != "name") {
								tmp.push(element[prop]);
							}
						}
						if (tmp.length > 0) {
							chartData.push(tmp);
						}
					});
				}
			} catch (error) {
				//console.log(error);
			} finally {
				row = 0;
				chartData.forEach((element) => {
					if (row === 1) {
						for (let num = 0; num < element.length; num++) {
							this.emissionsChartData[num].push(element[num]);
						}
					}
					row = 1;
				});
			}
		}
	},
};
</script>
