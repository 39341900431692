<template>
	<div>
		<b-form>
			<b-form-row>
				<b-col md="4" v-if="isAuthorized('admin, moma,moma_area')">
					<b-form-group label="Azienda, Ente, Scuola">
						<v-select v-model="company_id" :options="companies" label="name" :reduce="(x) => x.id"
							@input="updateCompany($event)" placeholder="Scrivi alcune lettere">
						</v-select>
					</b-form-group>
				</b-col>

				<b-col md="4">
					<b-form-group label="Sede">
						<b-form-select v-model="office_id" :options="offices" value-field="id"
							text-field="name"></b-form-select>
					</b-form-group>
				</b-col>

				<b-col md="4">
					<b-form-group label="Questionario">
						<b-form-select v-model="survey_id" :options="surveys" value-field="id"
							text-field="name"></b-form-select>
					</b-form-group>
				</b-col>
			</b-form-row>
		</b-form>


		<h1>Risposte al questionario {{ survey.name }}</h1>
		<b-row>
			<b-col cols="12" sm="2">
				<b-list-group>
					<b-list-group-item v-for="section in sections" :key="section.id" :ref="`section-${section.id}`"
						@click="openSection(section.id)" :active="section.id == activeSection">{{ section.name }}
					</b-list-group-item>
				</b-list-group>
			</b-col>

			<b-col cols="12" sm="10">
				<b-row>
					<b-col cols="12" sm="10" class="mb-2">
						<b>Domande
							<i class="fa fa-arrow-right" aria-hidden="true"></i>
						</b>
						<span v-for="question in filterQuestions(activeSection)" variant="primary" :key="question.id">
							<b-badge href="#" @click="openQuestion(question)" variant="primary" pill
								v-if="activeSection != null">
								{{ question.name }} </b-badge>&nbsp;
						</span>
					</b-col>

					<b-col cols="12" sm="2">
						<b-button variant="outline-primary" size="sm" @click="exportXls()">
							<i class="fa fa-file-excel-o" aria-hidden="true"></i>
							Esporta</b-button>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col cols="12" sm="1" class="mb-2">
						<b>Filtri
							<i class="fa fa-arrow-right" aria-hidden="true"></i>
						</b>
					</b-col>
					<b-col cols="12" sm="9" class="mb-2">
						<b-form-group>
							<v-select v-model="filters.questionList" @input="updateFilterQuestionList()"
								:options="filters.optionList" multiple />
						</b-form-group>
					</b-col>
					<b-col cols="12" sm="2">
						<b-form-checkbox variant="outline-primary" size="sm" v-model="filters.value_sort"
							@change="updateValues()">
							Ordinati
						</b-form-checkbox>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" sm="12">
						<b-list-group-item v-if="filters.answerFilters.length">
							<b-row>
								<b-col cols="3" v-for="filter in filters.answerFilters" :key="filter.name">
									<b-form-group>
										<label>{{ filter.name }}</label>
										<b-form-select size="sm" v-model="filter.value" :options="filter.options"
											value-field="id" text-field="name" @change="updateValues()"></b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
						</b-list-group-item>
					</b-col>
				</b-row>
				<hr />
				<h3>{{ currentQuestion.description }}</h3>
				<div class="muted" v-if="currentQuestion.long_description && currentQuestion.long_description.description">
					{{ currentQuestion.long_description.description }}</div>
				<hr />
				<b-tabs content-class="mt-3" @input="onTabChange()">
					<b-tab title="Tabella" active>
						<b-card :title="currentQuestion.title">
							<div class="mb-2" v-if="activeQuestion != null">
								<b-form-checkbox v-model="stickyHeader" inline>Intestazione fissa / Sticky
									header</b-form-checkbox>
								<b-form-checkbox v-model="noCollapse" inline>Unisci bordi / No border
									collapse</b-form-checkbox>
							</div>
							<b-table striped hover :items="makeTableData" :fields="makeTableLabel" responsive
								:sticky-header="stickyHeader" :no-border-collapse="noCollapse" :sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc" bordered small v-if="activeQuestion != null">
								<template #cell(descrizione)="data">
									<a v-if="!isAnonymous &&
										questionType !== 'array' &&
										questionType !== 'multiple'
										" href="#detail" @click="getDetail(data.value)">
										{{ data.value }}
										<b-icon icon="box-arrow-down" title="dettaglio"></b-icon>
									</a>
									<span v-else>{{ data.value }}</span>
								</template>
							</b-table>
						</b-card>
					</b-tab>

					<b-tab title="Istogramma" @click="activeQuestion != null ? histData() : null" style="height: 950px">
						<GChart v-if="questionType != 'array' && activeQuestion != null" type="BarChart"
							:options="histoOptions" :data="hist">
						</GChart>
						<b-tabs content-class="mt-3" v-else-if="questionType == 'array' && activeQuestion != null">
							<b-tab v-for="(hist_a, index) in hist" :key="index" :title="histitle[index]">
								<b-card :title="histitle[index]" :key="index">
									<GChart :key="index" type="BarChart" :options="histoOptions" :data="hist_a"></GChart>
								</b-card>
							</b-tab>
						</b-tabs>
						<h3 v-else>{{ message }}</h3>
					</b-tab>

					<b-tab id="pie" title="Torta" @click="activeQuestion != null ? pieData() : null">
						<GChart v-if="questionType != 'array' && activeQuestion != null" type="PieChart"
							:options="pieOptions" :data="pie"></GChart>
						<b-tabs content-class="mt-3" v-else-if="questionType == 'array' && activeQuestion != null">
							<b-tab v-for="(pie_a, index) in pie" :key="index" :title="pie_a[0][1]">
								<b-card :title="pie_a[0][1]" :key="index">
									<GChart :key="index" type="PieChart" :options="pieOptions" :data="pie_a"></GChart>
								</b-card>

							</b-tab>

						</b-tabs>

						<h3 v-else>{{ message }}</h3>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>

		<b-row v-if="!isAnonymous">
			<b-col>
				<h2 id="detail">Utenti appartenenti alla categoria selezionata</h2>
				<b-table :items="detailData" :fields="detailLabels">
					<template #cell(email)="data">
						{{ data.value }}
						<b-link :to="getSurveyAnswers(data.item.id)" title="Vai al questionario"><b-icon
								icon="calendar2-check" /></b-link>
					</template>
				</b-table>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import UserService from "@/services/user.service";
import Util from "@/mixing/util";
import XLSX from "xlsx";
import vSelect from "vue-select";
import { GChart } from "vue-google-charts";

export default {
	name: "QuestionariStats",
	components: {
		GChart,
		vSelect,
	},
	mixins: [Util],
	data() {
		return {
			stickyHeader: true,
			noCollapse: false,
			sortBy: "descrizione",
			sortDesc: false,
			survey: [],
			sections: [],
			activeQuestion: null,
			activeSection: null,
			stats: [],
			hist: null,
			histitle: [],
			pie: null,
			histoOptions: {
				title: null,
				width: "100%",
				height: 700,
				fontName: "Sofia Sans",
				showLabel: true,
				// fontSize: 25,
				vAxis: {
					title: "",
				},
				legend: {
					position: "right",
				},
				chartArea: {
					left: 50,
					right: 450
				},
			},
			pieOptions: {
				title: null,
				fontName: "Sofia Sans",
				titleTextStyle: {
					bold: false,
				},
				pieHole: 0.35,
				width: 1300,
				height: 700,
				donut: true,
				showLabel: true,
				chartPadding: 0,
				labelOffset: 20,
				labelDirection: "explode",
				legend: { position: "right", alignment: "center" },
				chartArea: {
					left: 40,
					right: 550
				},
			},
			filters: {
				questionList: [],
				optionList: [],
				answerFilters: [],
				value_sort: false,
			},
			company_id: null,
			office_id: null,
			labels: [],
			series: [],
			offices: [],
			companies: [],
			surveys: [],
			questions: [],
			survey_id: null,
			tableLabels: [{ key: "descrizione", stickyColumn: true, isRowHeader: true, sortable: true }, { key: "qta", sortable: true }],
			questionType: null,
			theCurrentQuestion: null,
			tableLabelsArrayQuestions: null,
			message: "grafico non disponibile.",
			detailData: [],
			detailLabels: [
				{
					key: "email",
					label: "Email",
				},
				{
					key: "first_name",
					label: "Nome",
				},
				{
					key: "last_name",
					label: "Cognome",
				},
			],
		};
	},

	computed: {
		isAnonymous() {
			return (
				this.survey.sending_mode == "a" || this.survey.sending_mode == null
			);
		},
		isNominal() {
			return this.survey.sending_mode == "n";
		},
		isAnonimized() {
			return this.survey.sending_mode == "z";
		},
		currentQuestion() {
			let self = this;
			//Restituisco vuoto
			if (this.activeQuestion == null || this.questions == undefined) {
				return {
					survey: [],
					sections: [],
					activeQuestion: null,
					activeSection: null,
					stats: [],
					hist: null,
					histitle: [],
					pie: null,
					histoOptions: {
						title: null,
						width: 1300,
						height: 700,
						fontName: "Segoe UI",
						// fontSize: 25,
						vAxis: {
							title: "",
						},
						legend: { position: "right" },
					},
					pieOptions: {
						title: null,
						width: 1300,
						height: 450,
						donut: true,
						showLabel: true,
						chartPadding: 30,
						labelOffset: 50,
						labelDirection: "explode",
					},
					labels: [],
					series: [],
					survey_id: null,
					tableLabels: [{ key: "descrizione", stickyColumn: true, isRowHeader: true, sortable: true }, { key: "qta", sortable: true }],
					questionType: null,
					theCurrentQuestion: null,
					tableLabelsArrayQuestions: null,
					message: "feature is in progress.....",
				};
			}
			if (self.questions != undefined && self.activeQuestion != undefined) {
				self.theCurrentQuestion = self.questions.find(
					(x) => x.id === self.activeQuestion
				);
				self.questionType = self.questions.find(
					(x) => x.id === self.activeQuestion).type;
				return self.questions.find((x) => x.id === self.activeQuestion);
			} else {
				return [];
			}

		},
		makeTableLabel() {
			if (this.questionType == "array") {
				return this.tableLabelsArrayQuestions;
			}
			if (this.questionType == "text" || this.questionType == "map") {
				return [{ key: "descrizione", stickyColumn: true, isRowHeader: true, sortable: true }];
			}
			return [...this.tableLabels, { key: "perc", sortable: true }];
		},
		makeTableData() {
			let items = [];
			let self = this;

			if (this.labels == undefined) {
				return [];
			}

			//let tableColumns = ["descrizione"];
			//let questionNames = [];

			if (this.questionType == "array") {
				let mainOptions = [];
				let subOptions = [];
				// subOptions.push("descrizione");
				subOptions.push({ key: "descrizione", stickyColumn: true, isRowHeader: true, sortable: true });

				self.labels.forEach((label) => {
					if (label !== "" && label !== "[]") {
						let index = label.indexOf("|");
						// let mainOption = label.substring(index + 1).trim();
						// let suboption = label.substring(0, index).trim();
						let suboption = label.substring(index + 1).trim();
						let mainOption = label.substring(0, index).trim();

						if (label.includes("altro") && !mainOptions.includes("altro")) {
							mainOptions.push("altro");
						} else if (
							!label.includes("altro") &&
							!mainOptions.includes(mainOption)
						) {
							mainOptions.push(mainOption);
						}

						if (label.includes("altro") && !subOptions.includes("altro")) {
							subOptions.push("altro");
						} else if (
							!label.includes("altro") &&
							!subOptions.includes(suboption)
						) {
							subOptions.push({ key: suboption, sortable: true });
						}
					}
				});

				self.tableLabelsArrayQuestions = subOptions;

				mainOptions.forEach((mainOption) => {
					let temp = [];
					temp["descrizione"] = mainOption;
					let subOption_total_count = 0;
					for (let i = 0; i < self.labels.length; i++) {
						if (self.labels[i].includes(mainOption)) {
							subOption_total_count += parseInt(this.series[0][i]);
						}
					}

					for (
						let i = 0;
						i < self.labels.length && subOption_total_count > 0;
						i++
					) {
						if (self.labels[i].includes(mainOption)) {
							if (self.labels[i] == "altro") {
								temp[self.labels[i]] =
									self.series[0][i] +
									" (" +
									(
										(100 * parseInt(self.series[0][i])) /
										subOption_total_count
									).toFixed(2) +
									"%)";
							} else {
								let index = self.labels[i].indexOf("|");
								// let option = self.labels[i].substring(0, index).trim();
								let option = self.labels[i].substring(index + 1).trim();
								temp[option] =
									self.series[0][i] +
									" (" +
									(
										(100 * parseInt(self.series[0][i])) /
										subOption_total_count
									).toFixed(2) +
									"%)";
							}
						}
					}
					if (subOption_total_count == 0) {
						temp[mainOption] = "0 (0%)";
						items.push(temp);
					} else {
						items.push(temp);
					}
				});

				return items;
			}

			if (this.questionType == "text" || this.questionType == "map") {
				this.labels.forEach((label) => {
					let r = [];
					r["descrizione"] = label;
					items.push(r);
				});
				return items;
			}

			let total = 0;
			let l = this.labels.length;
			this.series.forEach((row) => {
				for (let c = 0; c < l; c++) {
					total += row[c];
				}
			});

			this.series.forEach((row) => {
				for (let c = 0; c < l; c++) {
					let r = [];
					r["descrizione"] = self.labels[c];
					r["qta"] = row[c];
					r["perc"] = ((row[c] / total) * 100).toFixed(1) + "%";
					items.push(r);
				}
			});
			return items;
		},
		filterString: function () {
			if (!this.filters.questionList) {
				return "";
			}
			return this.filters.questionList.map((x) => x.code).join();
		},
	},
	watch: {
		company_id(newV, oldV) {
			this.updateValues();
		},
		office_id(newV, oldV) {
			this.updateValues();
		},
		survey_id(newV, oldV) {
			this.updateSurvey();
			this.updateValues();
			// this.updateFiltri();
		},
	},
	methods: {
		// updateFiltri(){
		// 	this.filters.optionList = this.questions;
		// },
		async updateCompany() {
			this.offices = await this.createOfficeList();
			this.surveys = await this.createFilteredSurveysList();
		},
		async createFilteredSurveysList() {
			let res = await UserService.getSurveysList(this.company_id, this.office_id);

			this.surveys = [
				{
					id: null,
					name: "Tutti",
				},
				...res.data.surveyList,
			];
			return this.surveys;
		},
		async createOfficeList() {
			let self = this;
			let offices = {};
			try {
				let res = await UserService.getOffices(this.company_id);
				offices = res.data.offices;
				offices.unshift({
					id: 0,
					name: "--- Filtra per Sede  --- ",
				});
			} catch (e) {
				self.$bvToast.toast(e.message, {
					title: "Errore",
					autoHideDelay: 2000,
					appendToast: true,
				});
			}
			return offices;
		},
		getSurveyAnswers(user_id) {
			return `/questionari/answers/${this.survey_id}/${user_id}`;
		},
		async getDetail(answer) {
			let filters = {};

			for (let flt of this.filters.answerFilters) {
				if (flt.value) {
					filters[flt.name] = flt.value;
				}
			}
			let response = await UserService.getAnswerIndicator(
				this.theCurrentQuestion.name,
				this.company_id,
				this.office_id,
				filters,
				!this.filters.value_sort,
				this.survey_id,
				answer
			);
			//Se è un dettaglio il risultato è in list
			if (response.data.list !== null) {
				this.detailData = response.data.list;
			}
		},
		histData() {
			this.hist = null;
			this.hist = [[""], [""]];
			let self = this;
			let items = [];
			if (this.questionType == "array") {
				let mainOptions = [];
				let subOptions = [];
				this.hist = [];
				this.histitle = [];


				self.labels.forEach((label) => {
					if (label !== "" && label !== "[]") {
						let index = label.indexOf("|");
						let suboption = label.substring(index + 1).trim();
						let mainOption = label.substring(0, index).trim();

						if (label.includes("altro") && !mainOptions.includes("altro")) {
							mainOptions.push("altro");
						} else if (
							!label.includes("altro") &&
							!mainOptions.includes(mainOption)
						) {
							mainOptions.push(mainOption);
						}

						if (label.includes("altro") && !subOptions.includes("altro")) {
							subOptions.push("altro");
						} else if (
							!label.includes("altro") &&
							!subOptions.includes(suboption)
						) {
							subOptions.push(suboption);
						}
					}
				});

				// self.tableLabelsArrayQuestions = subOptions;

				mainOptions.forEach((mainOption) => {
					let temp = [];
					temp["descrizione"] = mainOption;
					let subOption_total_count = 0;
					for (let i = 0; i < self.labels.length; i++) {
						if (self.labels[i].includes(mainOption)) {
							subOption_total_count += parseInt(this.series[0][i]);
						}
					}

					for (
						let i = 0;
						i < self.labels.length && subOption_total_count > 0;
						i++
					) {
						if (self.labels[i].includes(mainOption)) {
							if (self.labels[i] == "altro") {
								temp[self.labels[i]] =
									self.series[0][i] +
									" (" +
									(
										(100 * parseInt(self.series[0][i])) /
										subOption_total_count
									).toFixed(2) +
									"%)";
							} else {
								let index = self.labels[i].indexOf("|");
								let option = self.labels[i].substring(index + 1).trim();
								temp[option] = parseFloat(self.series[0][i]);
							}
						}
					}
					if (subOption_total_count == 0) {
						temp[mainOption] = "0 (0%)";
						items.push(temp);
					} else {
						items.push(temp);
					}
				});
				
				let i = 0;
				items.forEach((element) => {
					//console.log(element);
					this.hist.push([[""], [""]]);
					Object.keys(element).forEach(key => {
						//console.log(key, element[key]);


						if (Number.isNaN(parseFloat(element[key]))) {
							this.histitle.push(element[key]);
							// this.hist[i][1].push(element[key]);
						} else {
							this.hist[i][0].push("" + key);
							this.hist[i][1].push(parseFloat(element[key]));

						}



					});
					i = i + 1;
				});
				//console.log("hist:");
				//console.log(this.hist);
				//console.log("histitle:");
				//console.log(this.histitle);
			} else {
				this.labels.forEach((element) => {
					this.hist[0].push("" + element);
				});
				this.series[0].forEach((element) => {
					this.hist[1].push(parseFloat(element));
				});
			}



		},
		pieData() {
			let self = this;
			let items = [];
			if (this.questionType == "array") {
				let mainOptions = [];
				let subOptions = [];
				this.pie = [];


				self.labels.forEach((label) => {
					if (label !== "" && label !== "[]") {
						let index = label.indexOf("|");
						let suboption = label.substring(index + 1).trim();
						let mainOption = label.substring(0, index).trim();

						if (label.includes("altro") && !mainOptions.includes("altro")) {
							mainOptions.push("altro");
						} else if (
							!label.includes("altro") &&
							!mainOptions.includes(mainOption)
						) {
							mainOptions.push(mainOption);
						}

						if (label.includes("altro") && !subOptions.includes("altro")) {
							subOptions.push("altro");
						} else if (
							!label.includes("altro") &&
							!subOptions.includes(suboption)
						) {
							subOptions.push(suboption);
						}
					}
				});

				// self.tableLabelsArrayQuestions = subOptions;

				mainOptions.forEach((mainOption) => {
					let temp = [];
					temp["descrizione"] = mainOption;
					let subOption_total_count = 0;
					for (let i = 0; i < self.labels.length; i++) {
						if (self.labels[i].includes(mainOption)) {
							subOption_total_count += parseInt(this.series[0][i]);
						}
					}

					for (
						let i = 0;
						i < self.labels.length && subOption_total_count > 0;
						i++
					) {
						if (self.labels[i].includes(mainOption)) {
							if (self.labels[i] == "altro") {
								temp[self.labels[i]] =
									self.series[0][i] +
									" (" +
									(
										(100 * parseInt(self.series[0][i])) /
										subOption_total_count
									).toFixed(2) +
									"%)";
							} else {
								let index = self.labels[i].indexOf("|");
								let option = self.labels[i].substring(index + 1).trim();
								temp[option] = parseFloat(self.series[0][i]);
							}
						}
					}
					if (subOption_total_count == 0) {
						temp[mainOption] = "0 (0%)";
						items.push(temp);
					} else {
						items.push(temp);
					}
				});

				//console.log(items);
				// this.hist.push(items);
				// //console.log(this.hist);	
				let i = 0;
				items.forEach((element) => {
					//console.log(element);
					this.pie.push([]);
					Object.keys(element).forEach(key => {
						//console.log(key, element[key]);

						//this.pie[i].push("" + );
						if (Number.isNaN(parseFloat(element[key]))) {
							this.pie[i].push([key, element[key]]);
						} else {
							this.pie[i].push([key, parseFloat(element[key])]);
						}



					});
					i = i + 1;
				});
				//console.log(this.pie);
			} else {
				this.pie = [this.tableLabels];
				for (let i = 0; i < this.labels.length; i++) {
					this.pie.push([String(this.labels[i]), parseFloat(this.series[0][i])]);
				}
				//console.log(this.pie);
			}
			// this.$forceUpdate();
		},
		openSection(section_id) {
			if (this.activeSection != section_id) {
				this.activeSection = section_id;
				this.activeQuestion = null; //Attivo la domanda corrente
				this.theCurrentQuestion = null;
			} else {
				this.activeSection = section_id;
			}

		},
		download($url) {
			this.btnloading = true;
			UserService.download($url).then(() => {
				this.btnloading = false;
			});
		},
		updateSurvey() {
			if (this.survey_id) {
				UserService.getSurveyView(this.survey_id).then(
					(response) => {
						this.survey = response.data.survey;
						this.questions = this.survey.questions;
						this.filters.optionList = this.questions.map((x) => ({
							label: x.name,
							code: x.id,
						}));
					},
					(error) => this.showError(error)
				);
			} else {
				this.survey = {
					company_id: null,
					name: "Tutti",
				};
			}
		},
		updateValues() {
			let filters = {};

			for (let flt of this.filters.answerFilters) {
				if (flt.value) {
					filters[flt.name] = flt.value;
				}
			}
			if (this.theCurrentQuestion != null) {
				UserService.getAnswerIndicator(
					this.theCurrentQuestion.name,
					this.company_id,
					this.office_id,
					filters,
					!this.filters.value_sort,
					this.survey_id
				)
					.then((response) => {
						if (response.data == null) {
							this.stats = [];
							this.labels = ["Risposte non presenti"];
							this.series = null;
							return;
						}
						this.labels = response.data.labels;
						this.series = response.data.series;

						this.histData();
						this.pieData();
						this.detailData = [];
					})
					.catch((error) => this.showError(error));
			}

		},
		openQuestion(question) {
			this.theCurrentQuestion = question;
			this.activeQuestion = question.id; //Attivo la domanda corrente
			this.$forceUpdate();
			if (this.filters.office_id == 0) {
				this.filters.office_id = null;
			}
			this.updateValues();
		},

		filterQuestions(section_id) {
			if (this.questions == undefined) {
				return [];
			}
			return this.questions.filter(function (m) {
				if (m._joinData == undefined) {
					return m.section_id == section_id;
				} else {
					return m._joinData.section_id == section_id;
				}
			});
		},
		onTabChange() {
			this.$forceUpdate();
		},
		getTableColumns() {
			if (this.labels == undefined) {
				return [];
			}
			return this.labels;
		},
		getTableRows() {
			if (this.series == undefined) {
				return [];
			}
			return this.series[0];
		},
		exportXls() {
			let x = [];
			let r = ["descrizione", "qta"];
			let l = this.getTableColumns();
			let v = this.getTableRows();

			x.push(r);
			for (let i = 0; i < l.length; i++) {
				r = [];
				r.push(l[i]);
				r.push(v[i]);
				x.push(r);
			}
			let wb = XLSX.utils.book_new();
			wb.Props = {
				Title: "Esportazione Excel",
				Subject: "Esportazione",
				Author: "--",
				CreatedDate: new Date(),
			};
			wb.SheetNames.push(this.theCurrentQuestion.name);
			let ws = XLSX.utils.aoa_to_sheet(x);
			wb.Sheets[this.theCurrentQuestion.name] = ws;
			XLSX.writeFile(wb, "indicatore.xlsx");
		},
		async updateFilterQuestionList() {
			// localStorage.setItem("filters.questionList", JSON.stringify(this.filters.questionList));

			// Find new elements and fetch them
			let current_ids = this.filters.answerFilters.map((x) => x.id);
			let ids_to_fetch = [];
			for (let flt of this.filters.questionList) {
				if (!current_ids.includes(flt.code)) {
					ids_to_fetch.push(flt.code);
				}
			}
			let response = await UserService.getOriginFiltersForAnswers(
				ids_to_fetch.join(),
				this.survey_id
			);
			this.filters.answerFilters.push(
				...response.data.filters.map((filter) => ({
					...filter,
					value: this.$route.query[`filter-${filter.name}`]
						? this.$route.query[`filter-${filter.name}`]
						: "",
				}))
			);

			// Now remove elements we no longer have in vue-select
			current_ids = this.filters.questionList.map((x) => x.code);
			this.filters.answerFilters = this.filters.answerFilters.filter((x) =>
				current_ids.includes(x.id)
			);

			await this.updateValues();
		},
	},
	async created() {
		this.loading = true;
		this.survey_id = this.$route.params.survey_id;
		if (this.$route.query.company_id) {
			this.company_id = parseInt(this.$route.query.company_id);
			this.updateCompany();
		}
		if (this.$route.query.office_id) {
			this.office_id = parseInt(this.$route.query.office_id);
		}
		if (this.$route.query.survey_id) {
			this.survey_id = parseInt(this.$route.query.survey_id);
		}

		this.loading = true;

		try {
			const res = await Promise.all([
				UserService.getCompanyList(),
			]);
			this.companies = [
				{
					id: null,
					name: "Tutte",
				},
				...res[0].data.companies,
			];

		} catch (e) {
			this.showError(e);
		}

		let self = this;
		self.questions = [];
		UserService.getQuestionList().then((response) => {
			this.filters.optionList = response.data.questions.map((x) => ({
				label: x.name,
				code: x.id,
			}));

			self.questions = response.data.questions;
		});


		this.updateSurvey();

		UserService.getSections().then(
			(response) => (this.sections = response.data.sections),
			(error) => this.showError(error)
		);

		let colors = null;
		//Get the colors from the settings in SurveysController
		UserService.getColors().then(
			(response) => {
				colors = response.data.data;
				if (colors != null) {
					this.histoOptions.colors = colors;
					this.pieOptions.colors = colors;
				}
			},
			(error) => this.showError(error)
		);
		this.loading = false;
	},
};
</script>

<style></style>
