const operatori = [
	{
		title: "ATM Milano",
		img: "atm1.jpg",
		text: "Gli abbonamenti ATM servono l'area metropolitana di Milano",
		next: "atm",
	},
	{
		title: "Trenord",
		img: "trenord1.jpg",
		text: "Gli abbonamenti Trenord servono l'area metropolitana di Milano",
		next: "trenord",
	},
	{
		title: "GTT",
		img: "gtt.jpg",
		text: "Gli abbonamenti GTT servono l'area metropolitana di Torino",
		next: "gtt",
	},
	{
		title: "ATAC",
		img: "atac.jpg",
		text: "Gli abbonamenti ATAC servono Roma ed il Lazio",
		next: "atac",
	},
	{
		title: "Trenitalia",
		img: "trenitalia1.jpg",
		text: "Gli abbonamenti Trenitalia servono l'intero territorio Regionale",
		next: "",
	},
];

export default operatori;
